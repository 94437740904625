import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 160px;
  background: linear-gradient(to right, #00621d, #0ba33d 90%);
  margin-bottom: 45px;


  div#link-actions {
    position: absolute;
    right: 55px;
    top: 25px;

  }

  @media (max-width: 535px) {
    height: 100px;
    margin-bottom: 65px;

    div#link-actions {
    position: absolute;
    right: 10px;
    top: 2px;

  }
  }


`;

export const LogoContainer = styled.div``;

export const WhiteContainer = styled.div`
  width: 70%;
  height: 50px;
  position: absolute;
  top: 135px;
  left: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  padding-right: 50px;
  background: white;
  text-align: center;
  font-size: 12px;

  border-bottom-width: 6px;
  border-bottom-style: solid;
  border-bottom-color: coral;

  button {
    border: none;
    background: none;

    &:focus {
      outline: none;
    }
  }

  div#title-page {
    width: 100%;
    margin-left: 15px;

    h3 {
      margin-bottom: 0px;
      font-weight: normal;
    }
  }

  span#icon-button-home {
    svg {
      color: #333;
      &:hover {
        color: coral;
      }
    }
  }

  @media (max-width: 535px) {
    height: 45px;
    width: 100%;

    top: 100px;
    left: 0px;
    h3 {
      font-size: 18px;
      font-weight: 400;
    }
  }
`;

export const LogoImage = styled.img`
  margin-top: 23px;

  @media (max-width: 535px) {
    margin-top: 10px;
  }
`;
