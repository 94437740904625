import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Container } from './styles';
import {
  updateCurrentPage,
  setTipo,
  setValor,
} from '../../../store/modules/unidade/actions';

export default function ButtonTipo({ image, name, alt, tipo, valor }) {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleClickButton() {
    dispatch(updateCurrentPage(1));
    dispatch(setTipo(tipo));
    dispatch(setValor(valor));

    history.push('/resultados');
  }
  return (
    <Container>
      <div className="button-tipo-unidade" onClick={() => handleClickButton()}>
        <div>
          <img className="icon-tipo-unidade" src={image} alt={alt} />
        </div>

        <div id="titulo">{name}</div>
      </div>
    </Container>
  );
}
