import React from 'react';
import ButtonTipo from './ButtonTipo';
import LogoPmce from '../../assets/logo-pmce.png';
import LogoAdm from '../../assets/logo-adm.svg';
import LogoRegional from '../../assets/logo-ceara.png';
import LogoCpe from '../../assets/logo-especializado.png';
import LogoChoque from '../../assets/logo-cpchoque.png';
import LogoRaio from '../../assets/logo-raio.png';
import LogoUniseg from '../../assets/logo-uniseg.png';

export default function Home() {
  return (
    <>
      <div className="row justify-content-center">
        <label>Buscar pelo tipo de unidade</label>
      </div>
      <div className="row justify-content-center">
        <ButtonTipo
          name="Todas as unidades"
          image={LogoPmce}
          alt="logo-pmce"
          tipo=""
          valor=""
        />
        <ButtonTipo
          name="Unidades Administrativas"
          image={LogoAdm}
          alt="logo-amd"
          tipo="grandeComando"
          valor="ADMINISTRATIVA"
        />
        <ButtonTipo
          name="Comandos Regionais"
          image={LogoRegional}
          alt="logo-regional"
          tipo="grandeComando"
          valor="CRPM"
        />
        <ButtonTipo
          name="Comando Especializado"
          image={LogoCpe}
          alt="logo-cpe"
          tipo="grandeComando"
          valor="CPE"
        />
      </div>
      <div className="row justify-content-center">
        <ButtonTipo
          name="Comando Choque"
          image={LogoChoque}
          alt="logo-choque"
          tipo="grandeComando"
          valor="CPCHOQUE"
        />
        <ButtonTipo
          name="Comando Raio"
          image={LogoRaio}
          alt="logo-raio"
          tipo="grandeComando"
          valor="CPRAIO"
        />
        <ButtonTipo
          name="Unidade Integrada de Segurança"
          image={LogoUniseg}
          alt="logo-raio"
          tipo="ais"
          valor="TODOS"
        />
      </div>
    </>
  );
}
