import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Col, InputGroup, Button, FormControl } from 'react-bootstrap';
import Popover from '../Popover';
import { FaQuestionCircle } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { Container } from './styles';
import api from '../../services/api';
import {
  updateCurrentPage,
  setUnidade,
  setCidadeSede,
  setComandante,
  setCidadeAtuacao,
  setAreaAtuacao,
  setDisabledArea,
  setTipo,
  setValor,
  setArea,
} from '../../store/modules/unidade/actions';

export default function FilterPanel() {
  const history = useHistory();
  const dispatch = useDispatch();
  const unidade = useSelector((state) => state.unidade.unidade);
  const comandante = useSelector((state) => state.unidade.comandante);
  const cidadeSede = useSelector((state) => state.unidade.cidadeSede);
  const cidadeAtuacao = useSelector((state) => state.unidade.cidadeAtuacao);
  const areaAtuacao = useSelector((state) => state.unidade.areaAtuacao);
  const disabledArea = useSelector((state) => state.unidade.disabledArea);
  const [cidadesSede, setCidadesSede] = useState([]);
  const [cidadesAtuacao, setCidadesAtuacao] = useState([]);
  const [areasAtuacao, setAreasAtuacao] = useState([]);

  useEffect(() => {
    async function load() {
      try {
        const responseCidadeSede = await api.get(`resultados/getCidadesSede`);
        const responseCidadeAtuacao = await api.get(
          `resultados/getCidadesAtuacao`
        );
        setCidadesSede(responseCidadeSede.data);
        setCidadesAtuacao(responseCidadeAtuacao.data);
      } catch (error) {}
    }

    load();
  }, []);

  async function loadAreas(cidade) {
    try {
      const response = await api.get(
        `resultados/getBairrosDistritos?cidade=${cidade}`
      );
      setAreasAtuacao(response.data);
      dispatch(setDisabledArea(false));
    } catch (error) {}
  }

  function handleUnidade(unidade) {
    dispatch(setUnidade(unidade));
  }

  function handleComandante(comandante) {
    dispatch(setComandante(comandante));
  }

  function handleKeyComandante(e) {
    if (e.keyCode === 13) {
      if (comandante !== '') {
        dispatch(setComandante(e.target.value));
        dispatch(updateCurrentPage(1));
        history.push(`/resultados`);
        dispatch(setUnidade(''));
        dispatch(setCidadeSede(''));
        dispatch(setCidadeAtuacao(''));
        dispatch(setAreaAtuacao(''));
        dispatch(setDisabledArea(true));
        dispatch(setTipo('comandante'));
        dispatch(setValor(comandante));
      }
    }
  }

  function handleSearchComandante() {
    if (comandante !== '') {
      dispatch(updateCurrentPage(1));
      history.push(`/resultados`);
      dispatch(setUnidade(''));
      dispatch(setCidadeSede(''));
      dispatch(setCidadeAtuacao(''));
      dispatch(setAreaAtuacao(''));
      dispatch(setDisabledArea(true));
      dispatch(setTipo('comandante'));
      dispatch(setValor(comandante));
    }
  }

  function handleKeyUnidade(e) {
    if (e.keyCode === 13) {
      if (unidade !== '') {
        dispatch(setUnidade(e.target.value));
        dispatch(updateCurrentPage(1));
        history.push(`/resultados`);
        dispatch(setComandante(''));
        dispatch(setCidadeAtuacao(''));
        dispatch(setCidadeSede(''));
        dispatch(setAreaAtuacao(''));
        dispatch(setDisabledArea(true));
        dispatch(setTipo('unidade'));
        dispatch(setValor(e.target.value));
      }
    }
  }

  function handleCidadeSede(cidadeSede) {
    dispatch(setCidadeSede(cidadeSede));
    dispatch(updateCurrentPage(1));
    history.push(`/resultados`);
    dispatch(setUnidade(''));
    dispatch(setComandante(''));
    dispatch(setCidadeAtuacao(''));
    dispatch(setAreaAtuacao(''));
    dispatch(setDisabledArea(true));
    dispatch(setTipo('cidade'));
    dispatch(setValor(cidadeSede));
  }

  function handleCidadeAtuacao(cidadeAtuacao) {
    dispatch(setCidadeAtuacao(cidadeAtuacao));
    if (cidadeAtuacao !== 'default') {
      dispatch(updateCurrentPage(1));
      dispatch(setTipo('cidadeAtuacao'));
      dispatch(setValor(cidadeAtuacao));
      dispatch(setArea('TODOS'));
      history.push(`/resultados`);
      loadAreas(cidadeAtuacao);
    } else {
      dispatch(setDisabledArea(true));
    }
    dispatch(setUnidade(''));
    dispatch(setComandante(''));
    dispatch(setCidadeSede(''));
  }

  function handleAreaAtuacao(areaAtuacao) {
    dispatch(setAreaAtuacao(areaAtuacao));
    dispatch(updateCurrentPage(1));
    dispatch(setTipo('cidadeAtuacao'));
    dispatch(setValor(cidadeAtuacao));
    dispatch(setArea(areaAtuacao));
    history.push(`/resultados`);
  }

  function handleSearchUnidade() {
    if (unidade !== '') {
      dispatch(updateCurrentPage(1));
      dispatch(setTipo('unidade'));
      dispatch(setValor(unidade));

      history.push(`/resultados`);
      dispatch(setComandante(''));
      dispatch(setCidadeSede(''));
      dispatch(setCidadeAtuacao(''));
      dispatch(setAreaAtuacao(''));
      dispatch(setDisabledArea(true));
    }
  }

  return (
    <Container className="container">
      <Form>
        <Form.Row>
          <Form.Group as={Col} lg={4} xs={12} controlId="searchUnidade">
            <Form.Label>Buscar pelo nome da unidade</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Digite o nome da unidade"
                aria-label="Digite o nome da unidade"
                aria-describedby="basic-addon2"
                onChange={(e) => handleUnidade(e.target.value)}
                onKeyDown={(e) => handleKeyUnidade(e)}
                value={unidade}
              />
              <InputGroup.Append>
                <Button
                  className="search-button"
                  variant="outline-secondary"
                  onClick={() => handleSearchUnidade()}
                >
                  <FaSearch />
                </Button>
              </InputGroup.Append>
              <Popover
                id="button-help"
                title="Buscar por unidade"
                msg="Digite a sigla ou nome do local desejado. Digite o nome completo ou parte do nome.
                  Exemplo: 2ªcia/1ºbpm."
                action={['hover', 'focus']}
                place="top"
                icon={FaQuestionCircle}
                iconColor="#2E86C1 "
              />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg={4} xs={12} controlId="searchComandante">
            <Form.Label>Buscar pelo nome do comandante</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Digite o nome do comandante"
                aria-label="Digite o nome do comandante"
                aria-describedby="basic-addon2"
                onChange={(e) => handleComandante(e.target.value)}
                onKeyDown={(e) => handleKeyComandante(e)}
                value={comandante}
              />
              <InputGroup.Append>
                <Button
                  className="search-button"
                  variant="outline-secondary"
                  onClick={() => handleSearchComandante()}
                >
                  <FaSearch />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg={4} xs={12} controlId="searchCidadeSede">
            <Form.Label>Buscar pela cidade sede</Form.Label>
            <Form.Control
              as="select"
              placeholder="Selecione um cidade"
              value={cidadeSede}
              onChange={(e) => handleCidadeSede(e.target.value)}
            >
              <option key={-1} value="">
                Selecione uma cidade
              </option>
              {cidadesSede &&
                cidadesSede
                  .sort()
                  .map((cidade, index) => (
                    <option key={index}>{cidade}</option>
                  ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <fieldset className="scheduler-border">
          <legend className="scheduler-border">
            Buscar pela área de atuação
          </legend>
          <Form.Row>
            <Form.Group as={Col} lg={6} xs={12} controlId="searchCidadeAtuacao">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                as="select"
                value={cidadeAtuacao}
                onChange={(e) => handleCidadeAtuacao(e.target.value)}
              >
                <option value={'default'}>Selecione uma cidade</option>
                {cidadesAtuacao &&
                  cidadesAtuacao.sort().map((cidade, index) => (
                    <option key={index} value={cidade}>
                      {cidade}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} lg={6} xs={12} controlId="searchAreaAtuacao">
              <Form.Label>Bairro / Distrito</Form.Label>
              <Form.Control
                as="select"
                value={areaAtuacao}
                onChange={(e) => handleAreaAtuacao(e.target.value)}
                disabled={disabledArea}
              >
                <option>TODOS</option>
                {areasAtuacao &&
                  areasAtuacao
                    .sort()
                    .map((area, index) => <option key={index}>{area}</option>)}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </fieldset>
      </Form>
    </Container>
  );
}
