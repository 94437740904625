import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;

  div.form-group {
    margin-bottom: 2px;
    text-align: center;
  }
  @media (max-width: 400px) {
    margin-top: 15px;
  }

  fieldset.scheduler-border {
    border: 1px solid #ccc !important;
    border-radius: 4px;
    padding: 0 10px 10px 10px !important;

    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    width: 100%;
    text-align: center;

    @media (max-width: 990px) {
      margin-top: 16px;
    }
  }

  legend.scheduler-border {
    width: auto; /* Or auto */
    padding: 0 10px; /* To give a bit of padding on the left and right */
    border-bottom: none;
    font-size: 1rem;
    margin-bottom: 0;
  }

  button.btn-outline-secondary {
    background-color: #fff;
    border: 1px solid #ccc;
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;

    &:hover {
      color: #333;
    }

    &:focus {
      box-shadow: none;
    }
  }

  div.input-group-append {
    border-top-right-radius: 4px;
  }

  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child),
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show>.btn-outline-secondary.dropdown-toggle {
    color: #333;
    background-color: #fff;
    border-color: #fff;
  }

  button#button-help {
    padding-left: 5px;
    padding-right: 5px;
  }

  input {
    &:hover {
      border: 1px solid #ccc;
      border-right: none;
    }
    &:focus {
      border: 1px solid #ccc;
      border-right: none;
    }
  }

  .form-control:focus {
    border-color: #ccc;
  }
`;
