import produce from 'immer';

const INITIAL_STATE = {
  currentPage: 1,
  unidades: [],
  unidade: '',
  cidadeSede: '',
  comandante: '',
  cidadeAtuacao: '',
  areaAtuacao: 'TODOS',
  disabledArea: true,
  tipo: '',
  valor: '',
  area: '',
};

export default function aluno(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@unidade/UPDATE_CURRENT_PAGE': {
        draft.currentPage = action.payload;
        break;
      }
      case '@unidade/CLEAN_INPUTS': {
        draft.unidade = '';
        draft.cidadeSede = '';
        draft.comandante = '';
        draft.cidadeAtuacao = '';
        draft.areaAtuacao = 'TODOS';
        draft.disabledArea = true;
        break;
      }
      case '@unidade/SET_UNIDADE': {
        draft.unidade = action.payload;
        break;
      }
      case '@unidade/SET_COMANDANTE': {
        draft.comandante = action.payload;
        break;
      }
      case '@unidade/SET_CIDADE_SEDE': {
        draft.cidadeSede = action.payload;
        break;
      }
      case '@unidade/SET_CIDADE_ATUACAO': {
        draft.cidadeAtuacao = action.payload;
        break;
      }
      case '@unidade/SET_AREA_ATUACAO': {
        draft.areaAtuacao = action.payload;
        break;
      }
      case '@unidade/SET_TIPO': {
        draft.tipo = action.payload;
        break;
      }
      case '@unidade/SET_VALOR': {
        draft.valor = action.payload;
        break;
      }
      case '@unidade/SET_AREA': {
        draft.area = action.payload;
        break;
      }
      case '@unidade/SET_DISABLED_AREA': {
        draft.disabledArea = action.payload;
        break;
      }
      default:
    }
  });
}
