import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
export default createGlobalStyle`
  
  body {
  background: #e9e8e8;
  -webkit-font-smoothing: antialiased;

  }

  *:focus {
    outline: 0;
  }

  a:link {
  color: #000000;
  text-decoration: none;
}
a:visited {
  color: #000000;
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.form-control:focus {
  border-color: #666;
  -webkit-box-shadow: none;
  box-shadow: none;
}
  

`;
