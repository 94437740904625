import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Pagination } from 'react-bootstrap';
import Card from './Card';
import api from '../../services/api';

import {
  updateCurrentPage,
  setTipo,
  setValor,
} from '../../store/modules/unidade/actions';

import { Container } from './styles';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export default function Resultados() {
  const currentPage = useSelector((state) => state.unidade.currentPage);
  const tipo = useSelector((state) => state.unidade.tipo);
  const valor = useSelector((state) => state.unidade.valor);
  const area = useSelector((state) => state.unidade.area);
  const dispatch = useDispatch();
  const [unidades, setUnidades] = useState([]);

  const [crpms, setCrpms] = useState([]);
  const [crpm, setCrpm] = useState('CRPM');

  const [ais, setAis] = useState('TODOS');
  const [aises, setAises] = useState([]);

  const [uniseg, setUniseg] = useState('TODOS');
  const [unisegs, setUnisegs] = useState([]);

  const [loading, setLoading] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [activePrev, setActivePrev] = useState(false);
  const [activeFirst, setActiveFirst] = useState(false);
  const [activeNext, setActiveNext] = useState(false);
  const [activeLast, setActiveLast] = useState(false);

  // let query = useQuery();

  // const [tipo, setTipo] = useState(query.get('tipo'));
  // const [valor, setValor] = useState(query.get('valor'));
  // const area = query.get('area');

  function handlePageButton(e, number) {
    e.preventDefault();
    dispatch(updateCurrentPage(number));
    if (number > 1) {
      setActivePrev(true);
      setActiveFirst(true);
      if (number === totalPage) {
        setActiveNext(false);
        setActiveLast(false);
      } else {
        setActiveNext(true);
        setActiveLast(true);
      }
    } else {
      setActivePrev(false);
      setActiveFirst(false);
    }
  }

  function handlePrev() {
    if (currentPage > 1) {
      dispatch(updateCurrentPage(currentPage - 1));
      if (currentPage === 2) {
        setActivePrev(false);
        setActiveFirst(false);
      } else {
        setActivePrev(true);
      }
    }
  }

  function handleFirst() {
    dispatch(updateCurrentPage(1));
    setActiveFirst(false);
    setActivePrev(false);
    setActiveLast(true);
    setActiveNext(true);
  }

  function handleNext() {
    if (currentPage < totalPage) {
      dispatch(updateCurrentPage(currentPage + 1));
      setActivePrev(true);
      setActiveFirst(true);
      if (currentPage === totalPage - 1) {
        setActiveNext(false);
        setActiveLast(false);
      } else {
        setActiveNext(true);
      }
    }
  }

  function handleLast() {
    dispatch(updateCurrentPage(totalPage));
    setActiveFirst(true);
    setActivePrev(true);
    setActiveLast(false);
    setActiveNext(false);
  }

  function handlePrevElipse() {
    if (currentPage > 4) {
      dispatch(updateCurrentPage(currentPage - 3));
      setActiveLast(true);
      setActiveNext(true);
    } else {
      dispatch(updateCurrentPage(currentPage - 2));
      setActiveLast(true);
      setActiveNext(true);
    }
  }

  function handleNextElipse() {
    if (currentPage < totalPage - 4) {
      dispatch(updateCurrentPage(currentPage + 3));
      setActiveFirst(true);
      setActivePrev(true);
    } else {
      dispatch(updateCurrentPage(currentPage + 2));
      setActiveFirst(true);
      setActivePrev(true);
    }
  }

  function handleChangeCrpm(crpm) {
    setCrpm(crpm);
    dispatch(updateCurrentPage(1));
  }

  function handleChangeAis(ais) {
    setAis(ais);
    dispatch(setTipo('ais'));

    dispatch(setValor(ais));
    setUniseg('TODOS');
    dispatch(updateCurrentPage(1));
  }

  function handleChangeUniseg(uniseg) {
    if (uniseg === 'TODOS') {
      setUniseg(uniseg);

      dispatch(setTipo('ais'));
      dispatch(setValor(ais));
    } else {
      setUniseg(uniseg);
      dispatch(setTipo('uniseg'));
      dispatch(setValor(uniseg));
    }

    dispatch(updateCurrentPage(1));
  }

  useEffect(() => {
    async function load() {
      try {
        const crpms = await api.get(`resultados/getCrpms`);
        const aises = await api.get(`resultados/getAis`);

        setCrpms(crpms.data);
        setAises(aises.data);
      } catch (error) {}
    }
    load();
  }, []);

  useEffect(() => {
    async function load() {
      try {
        const unisegs = await api.get(`resultados/getUnisegs?ais=${ais}`);

        setUnisegs(unisegs.data);
      } catch (error) {}
    }
    load();
  }, [ais]);

  useEffect(() => {
    async function loadUnidades() {
      setLoading(true);
      let response = null;

      try {
        if (area) {
          response = await api.get(
            `resultados?tipo=${tipo}&valor=${valor}&area=${area}&page=${currentPage}`
          );
        } else if (tipo) {
          response = await api.get(
            `resultados?tipo=${tipo}&valor=${
              valor === 'CRPM' ? crpm : valor
            }&page=${currentPage}`
          );
        } else {
          console.log('entrou no else');
          response = await api.get(`resultados?page=${currentPage}`);
        }
        setUnidades(response.data.unidades);
        setTotalPage(response.data.totalPage);
        setTotal(response.data.total);
        setLoading(false);
        if (
          response.data.totalPage > 1 &&
          currentPage < response.data.totalPage
        ) {
          setActiveNext(true);
          setActiveLast(true);
        } else {
          setActiveNext(false);
          setActiveLast(false);
        }
      } catch (error) {}
    }

    loadUnidades();
  }, [area, tipo, valor, currentPage, crpm, ais]);

  function createButtonsPagination() {
    let items = [];
    if (totalPage > 6 && currentPage > 2) {
      items.push(
        <Pagination.Ellipsis
          key="elipseprev"
          onClick={() => handlePrevElipse()}
        />
      );
    }

    if (totalPage > 6) {
      for (let number = currentPage - 1; number <= currentPage + 1; number++) {
        if (number > 0 && number <= totalPage) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={(e) => handlePageButton(e, number)}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
    } else {
      for (let number = 1; number <= totalPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === currentPage}
            onClick={(e) => handlePageButton(e, number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    if (totalPage > 6 && currentPage < totalPage - 1) {
      items.push(
        <Pagination.Ellipsis
          key="elipsenext"
          onClick={() => handleNextElipse()}
        />
      );
    }
    return items;
  }

  return (
    <Container>
      <div className="header-search">
        <div>
          {loading && currentPage === 1
            ? 'Buscando unidades...'
            : unidades.length > 0
            ? `Sua pesquisa encontrou ${total} resultados`
            : 'Nenhum resultado encontrado'}
        </div>
        {valor === 'CRPM' && (
          <div id="select-crpm">
            <label htmlFor="crpm">CRPM:</label>
            <select
              id="crpm"
              className="form-control"
              onChange={(e) => handleChangeCrpm(e.target.value)}
            >
              <option value="CRPM">TODOS</option>
              {crpms &&
                crpms.map((crpm, index) => <option key={index}>{crpm}</option>)}
            </select>
          </div>
        )}
        {(tipo === 'ais' || tipo === 'uniseg') && (
          <div id="selects-ais-uniseg">
            <div id="select-ais">
              <label htmlFor="ais">AIS:</label>
              <select
                id="ais"
                className="form-control"
                onChange={(e) => handleChangeAis(e.target.value)}
              >
                <option value="TODOS">TODOS</option>
                {aises &&
                  aises
                    .sort()
                    .map((crpm, index) => <option key={index}>{crpm}</option>)}
              </select>
            </div>
            <div id="selec-uniseg">
              <label htmlFor="uniseg">UNISEG:</label>
              <select
                id="uniseg"
                className="form-control"
                onChange={(e) => handleChangeUniseg(e.target.value)}
                value={uniseg}
              >
                <option value="TODOS">TODOS</option>
                {unisegs &&
                  unisegs
                    .sort()
                    .map((crpm, index) => <option key={index}>{crpm}</option>)}
              </select>
            </div>
          </div>
        )}
      </div>
      <hr />
      {loading ? (
        <div
          id="spinner-container"
          className="row row-resultados justify-content-center"
        >
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div className="row row-resultados">
          {unidades &&
            unidades.map((unidade, index) => (
              <Card unidade={unidade} key={index} />
            ))}
        </div>
      )}
      <div className="row justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => handleFirst()}
            disabled={!activeFirst}
          />
          <Pagination.Prev
            onClick={() => handlePrev()}
            disabled={!activePrev}
          />
          <Pagination>{createButtonsPagination()}</Pagination>

          <Pagination.Next
            onClick={() => handleNext()}
            disabled={!activeNext}
          />
          <Pagination.Last
            onClick={() => handleLast()}
            disabled={!activeLast}
          />
        </Pagination>
      </div>
    </Container>
  );
}
