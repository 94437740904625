import styled from 'styled-components';

export const Container = styled.div`
  hr {
    margin-top: 2px;
  }

  div#spinner-container {
    width: 100%;

    padding-top: 20px;
  }
  div.row-resultados {
    min-height: 560px;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  div#selects-ais-uniseg {
    display: flex;
    flex-direction: row;

    select {
      width: 150px;
    }

    div + div {
      margin-left: 16px;
    }
  }

  div.header-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
  }

  div#select-crpm {
    /* display: flex;
      justify-content: row;
      align-items: center; */
    select {
      width: 150px;
    }
    label {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 480px) {
    div#selects-ais-uniseg {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;

      div {
        width: 100%;
        select {
          width: 100%;
        }
      }
    }

    div#select-crpm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;

      select {
        width: 100%;
        margin: 0;
      }
    }
  }
`;
