import React from 'react';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';
export default function PopoverComponent({ id, title, msg, action,
  place, icon : Icon, iconColor, size, link, textLink }) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>
        {msg}
        <br/>
        {link &&
        (<a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          >

          <strong>{textLink}</strong></a>)}
      </Popover.Content>
    </Popover>
  );
  return (
    <OverlayTrigger trigger={action} placement={place} overlay={popover}>
      <Button variant="link" id={id}>
        <Icon aria-hidden="true" color={iconColor} size={size}/>
      </Button>
    </OverlayTrigger>
  );
}
