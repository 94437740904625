export function loadUnidadesRequest(unidades) {
  return {
    type: '@unidade/LOAD_UNIDADES',
    payload: unidades,
  };
}

export function updateCurrentPage(page) {
  return {
    type: '@unidade/UPDATE_CURRENT_PAGE',
    payload: page,
  };
}

export function cleanInputs() {
  return {
    type: '@unidade/CLEAN_INPUTS',
  };
}

export function setUnidade(value) {
  return {
    type: '@unidade/SET_UNIDADE',
    payload: value,
  };
}

export function setComandante(value) {
  return {
    type: '@unidade/SET_COMANDANTE',
    payload: value,
  };
}

export function setCidadeSede(value) {
  return {
    type: '@unidade/SET_CIDADE_SEDE',
    payload: value,
  };
}

export function setCidadeAtuacao(value) {
  return {
    type: '@unidade/SET_CIDADE_ATUACAO',
    payload: value,
  };
}

export function setAreaAtuacao(value) {
  return {
    type: '@unidade/SET_AREA_ATUACAO',
    payload: value,
  };
}

export function setTipo(value) {
  return {
    type: '@unidade/SET_TIPO',
    payload: value,
  };
}

export function setValor(value) {
  return {
    type: '@unidade/SET_VALOR',
    payload: value,
  };
}

export function setArea(value) {
  return {
    type: '@unidade/SET_AREA',
    payload: value,
  };
}
export function setDisabledArea(value) {
  return {
    type: '@unidade/SET_DISABLED_AREA',
    payload: value,
  };
}
