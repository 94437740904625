import React from "react";
import Header from "../../components/Header";
import FilterPanel from "../../components/FilterPanel";
import Footer from "../../components/Footer";
import { Container } from "./styles";

export default function Layout({ children }) {

  return (
    <>
      <Header title="PMCE - CONTATOS" />
      <FilterPanel />
      <Container className="container">{children}</Container>
      <Footer />
    </>
  );
}
