import styled from 'styled-components';

export const Container = styled.div`
  .button-tipo-unidade {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    cursor: pointer;
    background: white;
    width: 160px;
    height: 160px;
    line-height: 18px;
    font-size: 14px;
    align-items: center;
    margin: 5px;
    background-clip: padding-box;

    div#titulo {
      text-align: center;
    }

    @media screen and (max-width: 480px) {
      width: 125px;
      height: 125px;
      padding: 14px;
      line-height: 14px;
      font-size: 0.8rem;

      .icon-tipo-unidade {
        height: 55px;
      }
      #titulo {
        margin-top: 4px;
      }
    }
  }

  .button-tipo-unidade:hover {
    background-color: #eeefff;
  }
`;
