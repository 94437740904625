import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'col-xl-4 col-lg-6 col-md-12',
})`
  h5 {
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    padding-right: 0px;
    padding-left: 0px;
  }
  .card-unidade {
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: coral;
  }

  .logo-card {
    height: 45px;
  }

  .card-header {
    padding: 8px 8px;
    min-height: 86px;
    display: flex;
    flex-direction: row;

    div {
      width: 100%;
    }

    div.container-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .col-2 {
        padding-left: 8px;
      }
    }

    #container-title-header {
      padding-left: 4px;
      padding-right: 8px;

      span + span {
        margin-left: 4px;
      }
    }
  }

  .title-card-header {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
  }

  .card-body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;

    @media (max-width: 768px) {
      .card-body {
        min-height: 130px;
      }
    }

    .contatos {
      color: #666;
      line-height: 1.3;
    }

    .card-body-funcao {
      text-align: left;
      font-size: 12px;
      font-weight: 600;
      padding-right: 0px;
      word-wrap: normal;
    }

    .card-body-policial {
      text-align: left;
      padding-left: 6px;
      font-size: 12px;
    }

    .row-contato {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;
    }

    .card-body-contato {
      text-align: left;

      font-size: 12px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .card-body-icon {
      margin-top: 0;
      align-content: flex-start;
      text-align: left;
      padding: 0;
    }
  }
`;
