import React from "react";
import Logo from "../../assets/logo.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import pdf from '../../assets/files/manual-contatos-v1.0.pdf';
import { FaRegQuestionCircle, FaHome } from "react-icons/fa";
import Popover from '../Popover';
import { cleanInputs } from '../../store/modules/unidade/actions';
import { Container, LogoContainer, WhiteContainer, LogoImage } from "./styles";
import { isMobile } from 'react-device-detect';

export default function Header({ title }) {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleHome() {
    history.push("/");
    dispatch(cleanInputs());
  }

  return (
    <Container>

      <LogoContainer className="container">
        <a href="https://www.pm.ce.gov.br/">
          <LogoImage className="img-fluid" src={Logo} alt="logotipo" />
        </a>
      </LogoContainer>
      <div id="link-actions">

        <Popover
                id="ajuda-contatos"
                title="Manual"
                msg="Manual do Sistema de Contatos"
                textLink="Baixar manual!"
                link={pdf}
                action={isMobile ? ['focus', 'hover'] : ['focus']}
                place="left"
                icon={FaRegQuestionCircle}
                iconColor="#fff"
                size={isMobile ? 20: 25}

        />

      </div>

      <WhiteContainer>
        <button onClick={handleHome}>
          <div className="button-home">
            <span id="icon-button-home">
              <FaHome size={25} />

            </span>
          </div>
        </button>
        <div
          id="title-page"
          className="justify-content-center align-items-center"
        >
          <h3>{title}</h3>
        </div>
      </WhiteContainer>
    </Container>
  );
}
