import React from 'react';
import { FaPhone, FaAt, FaHome } from 'react-icons/fa';
import { Badge } from 'react-bootstrap';
import { Container } from './styles';
import logoPmce from '../../../assets/logo-pmce.png';

export default function Card({ unidade }) {
  function formatPhone(phone) {
    return phone.replace(/^(\d{0,2})(\d{4,5})(\d{4}).*/, '($1) $2-$3');
  }

  function formatPhones(unidade) {
    let fones = '';

    if (unidade.opm_fone) {
      fones += formatPhone(unidade.opm_fone);
      if (unidade.opm_fone2) {
        fones += ` / ${formatPhone(unidade.opm_fone2)}`;
      }
    } else if (unidade.opm_fone2) {
      fones += formatPhone(unidade.opm_fone2);
    }

    return fones;
  }

  function formatEmails(unidade) {
    let emails = '';

    if (unidade.opm_email) {
      emails += unidade.opm_email;
      if (unidade.opm_email_alternativo) {
        emails += ` / ${unidade.opm_email_alternativo}`;
      }
    } else if (unidade.opm_email_alternativo) {
      emails += unidade.opm_email_alternativo;
    }

    return emails;
  }

  function formatEndereco(unidade) {
    const endereco = unidade.opm_endereco;
    const numero = unidade.opm_numero;
    const bairro = unidade.opm_bairro;
    const cidade = unidade.opm_municipio;
    const cep = unidade.opm_cep ? unidade.opm_cep.replace(/^(\d{5})(\d{3}).*/, '$1-$2') : '';

    return `${endereco}, ${numero} - ${bairro}, ${cidade}-CE, ${cep}`;
  }

  function formatComandante(unidade) {
    let comandante = '';
    if (
      unidade.opm_res_posgrad === 'NÃO INFORMADO' ||
      unidade.opm_res_posgrad === '' ||
      unidade.opm_res_posgrad === null
    ) {
      if (
        unidade.opm_cmte_posgrad === 'NÃO INFORMADO' ||
        unidade.opm_cmte_nome === 'NÃO INFORMADO'
      ) {
        comandante = '';
      } else {
        comandante = `${unidade.opm_cmte_posgrad} ${unidade.opm_cmte_nome}`;
      }
    } else {
      comandante = `${unidade.opm_res_posgrad} ${unidade.opm_res_nome} (Resp.)`;
    }

    return comandante;
  }

  return (
    <Container>
      <div className="card mb-4 shadow-sm card-unidade">
        <div className="card-header">
          <div className="container-header">
            <div className="col-2">
              <img className="logo-card" src={logoPmce} alt="logo pmce" />
            </div>
            <div id="container-title-header" className="col-10">
              <h5 className="title-card-header">{`${unidade.opm_nome} - ${unidade.opm_sigla}`}</h5>
              <div id="badges" className="align-items-center">
                <Badge pill variant="success">
                  {unidade.opm_ais}
                </Badge>
                <Badge pill variant="warning">
                  {unidade.opm_uniseg}
                </Badge>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div>
            <div className="row">
              <div className="col-4 card-body-funcao">Comandante:</div>
              <div className="col-8 card-body-policial">
                {formatComandante(unidade)}
              </div>
            </div>
            {unidade.opm_subcmte_posgrad !== 'NÃO INFORMADO' &&
              unidade.opm_subcmte_posgrad !== '' &&
              unidade.opm_subcmte_posgrad !== null && (
                <div className="row">
                  <div className="col-4 card-body-funcao">Subcomandante:</div>
                  <div className="col-8 card-body-policial">{`${
                    unidade.opm_subcmte_posgrad !== 'NÃO INFORMADO'
                      ? unidade.opm_subcmte_posgrad
                      : ''
                  } ${unidade.opm_subcmte_nome}`}</div>
                </div>
              )}
          </div>

          <div className="contatos">
            <div className="row-contato">
              <div className="col-1 card-body-icon">
                <FaPhone size={12} />
              </div>
              <div className="col-11 card-body-contato">
                {unidade && formatPhones(unidade)}
              </div>
            </div>
            <div className="row-contato">
              <div className="col-1 card-body-icon">
                <FaAt size={12} />
              </div>
              <div className="col-11 card-body-contato">
                {unidade && formatEmails(unidade)}
              </div>
            </div>
            <div className="row-contato contato">
              <div className="col-1 card-body-icon">
                <FaHome size={12} />
              </div>
              <div className="col-11 card-body-contato">
                {formatEndereco(unidade)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
