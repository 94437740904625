import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Resultados from '../pages/Resultados';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/resultados" component={Resultados} />
      <Route>
      <Home />
    </Route>
    </Switch>
  );
}
